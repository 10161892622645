import React from 'react';
import { Tabs, Tab, Box, useMediaQuery, useTheme } from '@mui/material';

interface ResponsiveTabsProps {
    tabIndex: number;
    handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const ResponsiveTabs: React.FC<ResponsiveTabsProps> = ({ tabIndex, handleTabChange }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 64,
                left: 0,
                right: 0,
                backgroundColor: '#b9d2d8',
                zIndex: 10,
                padding: isMobile ? '10px 0' : '0',
                marginTop: isMobile ? '-10px' : '0',
                borderBottom: '0px solid #ccc',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant={isMobile ? 'scrollable' : 'fullWidth'}
                scrollButtons={isMobile ? true : false}
                allowScrollButtonsMobile
                sx={{
                    maxWidth: '100%',
                    '.MuiTabs-indicator': {
                        backgroundColor: '#32484e',
                        height: '2px',
                    },
                    '.MuiTab-root': {
                        color: '#32484e',
                        fontWeight: 500,
                        textTransform: 'none',
                        minWidth: isMobile ? '120px' : '160px',
                        whiteSpace: 'nowrap',
                        padding: '10px 10px',
                    },
                    '.Mui-selected': {
                        color: '#32484e',
                    },
                    '.MuiTabScrollButton-root': {
                        color: '#32484e',
                    },
                }}
            >
                <Tab label="Pré-campanha" />
                <Tab label="Nova campanha" />
                <Tab label="Monitorar disparo" />
                <Tab label="Campanhas" />
                <Tab label="Contas" />
                <Tab label="Templates" />
                <Tab label="Minha conta" />
            </Tabs>
        </Box>
    );
};

export default ResponsiveTabs;
