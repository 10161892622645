import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FormData from 'form-data';
import {
    AppBar,
    Toolbar,
    Typography,
    TextField,
    Button,
    Box,
    MenuItem,
    Modal,
    Badge,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    CircularProgress,
    Grid,
} from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/system';
import { useLocation } from 'react-router-dom';
import Papa from 'papaparse';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Menu from '@mui/material/Menu';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FunnelData from './FunnelData';
import { useCallback } from 'react';
import ResponsiveTabs from './ResponsiveTabs';
import Account from './Account';
import reactStringReplace from 'react-string-replace';


export interface Disparo {
    campaignId: string;
    template: string;
    chatbot: string;
    masterBot: string;
    flowId: string;
    interval: string;
    stateId: string;
    key: string;
    data: string;
    totalContacts: number;
    totalDelivered: number;
    totalRead: number;
    totalFailed: number;
    totalClicked: number;
    templateQuantityParameters: string;
    files: {
        url: string;
        dispatched_at: string | null;
        batch_count: number;
    }[];
}




function Row({ disparo, index }: { disparo: Disparo; index: number }) {
    const [open, setOpen] = useState(false);
    const [toast, setToast] = useState<{
        open: boolean;
        message: string;
        severity: 'success' | 'error' | 'warning' | 'info';
    }>({
        open: false,
        message: '',
        severity: 'success',
    });

    const showToast = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
        setToast({ open: true, message, severity });
    };

    const handleDisparar = async (fileUrl: string) => {
        try {
            await axios.post(
                'https://n8n.dawntech.dev/webhook/broadcast/run',
                {
                    disparo: disparo,
                    fileUrl: fileUrl
                },
                {
                    headers: {
                        'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                    },
                }
            );
            showToast(`Arquivo disparado com sucesso!`, 'success');
        } catch (error) {
            console.error('Erro ao disparar arquivo:', error);
            showToast(`Erro ao disparar arquivo.`, 'error');
        }
    };

    const handleDispararTodos = async (disparo: Disparo) => {
        if (!disparo.files || disparo.files.length === 0) {
            showToast('Nenhum arquivo para disparar nesta campanha.', 'warning');
            return;
        }
        try {
            await axios.post(
                'https://n8n.dinnertwist.dawntech.dev/webhook/campaign/runall',
                disparo,
                {
                    headers: {
                        'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                    },
                }
            );
            showToast('Todos os arquivos disparados com sucesso!', 'success');
        } catch (error) {
            console.error('Erro ao disparar todos os arquivos:', error);
            showToast('Erro ao disparar todos os arquivos.', 'error');
        }
    };

    return (
        <>
            <Snackbar
                open={toast.open}
                autoHideDuration={3000}
                onClose={() => setToast({ ...toast, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={() => setToast({ ...toast, open: false })}
                    severity={toast.severity}
                    sx={{ width: '100%' }}
                >
                    {toast.message}
                </Alert>
            </Snackbar>
            <TableRow key={index}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{disparo.campaignId}</TableCell>
                <TableCell>{disparo.template}</TableCell>
                <TableCell>{disparo.chatbot}</TableCell>
                <TableCell>{disparo.data}</TableCell>
                <TableCell>{disparo.totalContacts || '-'}</TableCell>
                <TableCell>{disparo.totalDelivered || '-'}</TableCell>
                <TableCell>{disparo.totalRead || '-'}</TableCell>
                <TableCell>{disparo.totalClicked || '-'}</TableCell>
                <TableCell>{disparo.totalFailed || '-'}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={10} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ display: 'flex', gap: '20px', margin: '20px 0' }}>
                            <Box sx={{ flex: 1 }}>
                                {disparo.totalContacts ? (
                                    <FunnelData disparo={disparo} />
                                ) : (
                                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                                        Funil ainda não disponível
                                    </Typography>
                                )}
                            </Box>

                            {disparo.files?.length > 0 && (
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Arquivos base
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: '#32484e', color: '#fff', marginBottom: '10px' }}
                                        onClick={() => handleDispararTodos(disparo)}
                                    >
                                        Disparar Todos
                                    </Button>
                                    <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
                                        <Table size="small" aria-label="Arquivos da campanha">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Índice</TableCell>
                                                    <TableCell>URL</TableCell>
                                                    <TableCell>Batch</TableCell>
                                                    <TableCell>Data de Disparo</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {disparo.files?.length > 0 ? (
                                                    disparo.files.map((file, idx) => (
                                                        <TableRow key={file.url}>
                                                            <TableCell>#{idx + 1}</TableCell>
                                                            <TableCell>
                                                                <a href={file.url} target="_blank" rel="noopener noreferrer">
                                                                    Acessar base
                                                                </a>
                                                            </TableCell>
                                                            <TableCell>{file.batch_count}</TableCell>
                                                            <TableCell>
                                                                {file.dispatched_at && file.dispatched_at !== "null" && file.dispatched_at !== ""
                                                                    ? new Date(file.dispatched_at).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
                                                                    : 'Indisponível'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {/*!(file.dispatched_at && file.dispatched_at !== "null" && file.dispatched_at !== "") && (
                                                                    <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        sx={{
                                                                            backgroundColor: '#f05365',
                                                                            color: '#fff',
                                                                        }}
                                                                        onClick={() => handleDisparar(file.url)}
                                                                    >
                                                                        Disparar
                                                                    </Button>
                                                                )*/}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={5}>
                                                            <Typography variant="body2" sx={{ fontStyle: 'italic', textAlign: 'center' }}>
                                                                Nenhum arquivo disponível
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}


const DashboardHeader: React.FC<{ onLogout: () => void }> = ({ onLogout }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<null | HTMLElement>(null);
    const [notifications, setNotifications] = useState<any[]>([]);
    const [loadingNotifications, setLoadingNotifications] = useState(false);

    const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleNotificationsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setNotificationsAnchorEl(event.currentTarget);
        fetchNotifications();
    };

    const handleNotificationsClose = () => {
        setNotificationsAnchorEl(null);
    };

    const fetchNotifications = async () => {
        setLoadingNotifications(true);
        try {
            const response = await new Promise((resolve) =>
                setTimeout(() =>
                    resolve({
                        notifications: [
                            //{ id: '1234', text: 'Texto 1', notification_date: '2024-12-23T13:17:46.761Z' },
                            //{ id: '5678', text: 'Texto 2', notification_date: '2024-12-23T14:20:00.000Z' },
                        ],
                    }),
                    1000
                )
            );
            setNotifications((response as any).notifications || []);
        } catch (error) {
            console.error('Erro ao carregar notificações:', error);
        } finally {
            setLoadingNotifications(false);
        }
    };

    const markAsRead = async (id: string) => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            setNotifications((prev) => prev.filter((notification) => notification.id !== id));
        } catch (error) {
            console.error('Erro ao marcar notificação como lida:', error);
        }
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: '#32484e',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                marginBottom: 0,
                paddingBottom: 0,
            }}
        >

            <Toolbar sx={{ justifyContent: 'space-between', position: 'relative' }}>
                <img
                    src="https://appcatalyst.com.br/static/media/bns-t.ba39fc60127867b9199c.png"
                    alt="Logo"
                    style={{ height: '40px' }}
                />

                <Box>
                    <IconButton
                        color="inherit"
                        onClick={handleNotificationsOpen}
                        sx={{ marginRight: '15px', marginTop: '-30px' }}
                    >
                        <Badge color="error" badgeContent={notifications.length}>
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>

                    <Menu
                        anchorEl={notificationsAnchorEl}
                        open={Boolean(notificationsAnchorEl)}
                        onClose={handleNotificationsClose}
                        PaperProps={{ sx: { width: 300 } }}
                    >
                        {loadingNotifications ? (
                            <Box sx={{ padding: '10px', textAlign: 'center' }}>
                                <CircularProgress size={24} />
                            </Box>
                        ) : notifications.length > 0 ? (
                            <List>
                                {notifications.map((notification) => (
                                    <ListItem key={notification.id}>
                                        <ListItemText
                                            primary={notification.text}
                                            secondary={new Date(
                                                notification.notification_date
                                            ).toLocaleString()}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                color="primary"
                                                onClick={() => markAsRead(notification.id)}
                                            >
                                                <CheckIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography sx={{ padding: '10px', textAlign: 'center' }}>
                                Nenhuma notificação.
                            </Typography>
                        )}
                    </Menu>

                    <div
                        onClick={handleMenuOpen}
                        style={{
                            cursor: 'pointer',
                            display: 'inline-flex',
                            alignItems: 'center',
                            marginTop: '2px'
                        }}
                    >
                        <img
                            src="https://raw.githubusercontent.com/dawntech/assets.dawntech.dev/refs/heads/main/content/logo-small.jpg"
                            alt="User Avatar"
                            style={{
                                height: '40px',
                                width: '40px',
                                borderRadius: '50%',
                                border: '2px solid white',
                            }}
                        />
                    </div>

                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        PaperProps={{ sx: { width: 160, mt: 1 } }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleMenuClose();
                                onLogout();
                            }}
                        >
                            <Typography color="error">Sair</Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
};



const Footer = styled(Box)`
  background-color: #32484e;
  color: white;
  padding: 20px;
  text-align: center;
`;

const Login: React.FC<{ onLoginSuccess: () => void }> = ({ onLoginSuccess }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [toast, setToast] = useState<{
        open: boolean;
        message: string;
        severity: 'success' | 'error' | 'warning' | 'info';
    }>({
        open: false,
        message: '',
        severity: 'success',
    });

    useEffect(() => {
        const session = localStorage.getItem('session');
        if (session) {
            onLoginSuccess();
        }
    }, [onLoginSuccess]);

    const showToast = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
        setToast({ open: true, message, severity });
    };

    const handleLogin = () => {
        if (username === 'helpdesk' && password === 'gringo@2024') {
            localStorage.setItem('session', 'active');
            localStorage.setItem('username', username);
            showToast('Login realizado com sucesso!', 'success');
            onLoginSuccess();
        } else {
            showToast('Credenciais inválidas.', 'error');
        }
    };


    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <Box>
            <AppBar position="static" sx={{ backgroundColor: '#32484e' }}>
                <Toolbar sx={{ justifyContent: 'center' }}>
                    <img
                        src="https://appcatalyst.com.br/static/media/bns-t.ba39fc60127867b9199c.png"
                        alt="Logo"
                        style={{ height: '40px' }}
                    />
                </Toolbar>
            </AppBar>

            <Box
                sx={{
                    minHeight: 'calc(100vh - 160px)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#b9d2d8',
                }}
            >

                <Box
                    sx={{
                        padding: '20px',
                        borderRadius: '8px',
                        backgroundColor: 'white',
                        boxShadow: 3,
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h5" sx={{ marginBottom: '20px' }}>
                        Login
                    </Typography>
                    <TextField
                        fullWidth
                        label="Usuário"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        sx={{ marginBottom: '15px' }}
                    />
                    <TextField
                        fullWidth
                        label="Senha"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyPress}
                        sx={{ marginBottom: '20px' }}
                    />
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: '#32484e', color: '#fff' }}
                        onClick={handleLogin}
                    >
                        Entrar
                    </Button>
                </Box>
            </Box>

            <Snackbar
                open={toast.open}
                autoHideDuration={3000}
                onClose={() => setToast({ ...toast, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={() => setToast({ ...toast, open: false })}
                    severity={toast.severity}
                    sx={{ width: '100%' }}
                >
                    {toast.message}
                </Alert>
            </Snackbar>

            <Footer>
                <Typography>Copyright © 2024 Dawntech Inc.</Typography>
                <Typography>DAWNTECH CONSULTORIA LTDA</Typography>
                <Typography>CNPJ: 38.179.807/0001-06</Typography>
                <Typography>
                    Avenida Carlos Gomes, 700 sala 606, 5º Andar - Boa Vista, Porto Alegre, RS, Brasil,
                    90480-000
                </Typography>
                <Typography>
                    Telefones:
                    <a href="tel:+555135572998" style={{ color: 'white', textDecoration: 'none' }}>
                        +55 (51) 3557-2998
                    </a>{' '}
                    /
                    <a href="tel:+441245790914" style={{ color: 'white', textDecoration: 'none' }}>
                        +44 (1) 245 790 914
                    </a>{' '}
                    /
                    <a href="tel:+525584214909" style={{ color: 'white', textDecoration: 'none' }}>
                        +52 55 8421 4909
                    </a>
                </Typography>
            </Footer>
        </Box>
    );
};


const Dashboard: React.FC = () => {
    const location = useLocation();
    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState<string | null>(null);
    const [csvFile, setCsvFile] = useState<File | null>(null);
    const [csvFileName, setCsvFileName] = useState<string>('');
    const [batchCount, setBatchCount] = useState<string>('10000');
    const [interval, setInterval] = useState<string>('10');
    const [router, setRouter] = useState<string>('');
    const [key, setKey] = useState<string>('');
    const [routerId, setRouterId] = useState<string>('');
    const [template, setTemplate] = useState<string>('');
    const [flowId, setFlowId] = useState<string>('');
    const [masterBot, setMasterBot] = useState<string>('');
    const [stateId, setStateId] = useState<string>('');
    const [templateQuantityParameters, setTemplateQuantityParameters] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [uniqueContacts, setUniqueContacts] = useState<number>(0);
    const [campaigns, setCampaigns] = useState<Disparo[]>([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [loadingCampaigns, setLoadingCampaigns] = useState<boolean>(false);
    const [wabas, setWabas] = useState<any[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [newWabaId, setNewWabaId] = useState('');
    const [loadingWabas, setLoadingWabas] = useState(false);
    const [templates, setTemplates] = useState<any[]>([]);
    const [loadingTemplates, setLoadingTemplates] = useState(false);
    const [openTemplateModal, setOpenTemplateModal] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [notifications, setNotifications] = useState<any[]>([]);
    const [loadingNotifications, setLoadingNotifications] = useState(false);
    const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<null | HTMLElement>(null);
    const [generatedId, setGeneratedId] = useState<string | null>(null);
    const [campaignId, setCampaignId] = useState<string | null>(null);
    const [ctaUrl, setCtaUrl] = useState<string>('');
    const [ctaModalOpen, setCtaModalOpen] = useState(false);
    const [generatedCtaUrl, setGeneratedCtaUrl] = useState<string | null>(null);
    const [idOfCampaign, setIdOfCampaign] = useState<string>('');
    const [campaignIdInModal, setCampaignIdInModal] = useState('');
    const [preview, setPreview] = useState<any[]>([]);

    const handleGenerateUrl = () => {
        if (!campaignIdInModal || !router || !key || !ctaUrl) {
            showToast("Todos os campos são obrigatórios!", "warning");
            return;
        }

        const payload = {
            router: router,
            key: key,
            url: ctaUrl,
            campaign: campaignIdInModal,
        };

        const encodedPayload = btoa(JSON.stringify(payload)); // btoa: encode Base64

        const secureUrl = `https://api.dispatcher.dwnt.store/go?to=${encodedPayload}`;

        setGeneratedCtaUrl(secureUrl);
        showToast("URL gerada com sucesso!", "success");
    };

    const fetchNotifications = async () => {
        setLoadingNotifications(true);
        try {
            const response = await axios.get('https://n8n.dawntech.dev/webhook/notifications', {
                headers: {
                    'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                },
            });
            setNotifications(response.data.notifications || []);
        } catch (error) {
            console.error('Erro ao buscar notificações:', error);
            showToast('Erro ao carregar notificações.', 'error');
        } finally {
            setLoadingNotifications(false);
        }
    };

    const markAsRead = async (id: string) => {
        try {
            await axios.post(
                'https://n8n.dawntech.dev/webhook/notification/read',
                { id },
                {
                    headers: {
                        'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                    },
                }
            );
            setNotifications((prev) => prev.filter((notification) => notification.id !== id));
            showToast('Notificação marcada como lida!', 'success');
        } catch (error) {
            console.error('Erro ao marcar notificação como lida:', error);
            showToast('Erro ao marcar notificação como lida.', 'error');
        }
    };

    const handleNotificationsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setNotificationsAnchorEl(event.currentTarget);
        fetchNotifications();
    };

    const handleNotificationsClose = () => {
        setNotificationsAnchorEl(null);
    };

    const handleOpenCtaModal = () => {
        if (generatedId) {
            setCampaignIdInModal(generatedId);
        }
        setCtaModalOpen(true);
    };


    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewWabaId('');
    };



    const fetchWabas = useCallback(async () => {
        setLoadingWabas(true);
        try {
            const response = await axios.get('https://n8n.dawntech.dev/webhook/wabas', {
                headers: {
                    'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                },
            });

            const formattedWabas = response.data.wabas.map((waba: any) => ({
                id: waba?._id,
                name: waba?.name,
                number: waba?.number,
                status: waba?.status,
                lastUpdatedAt: waba?.updated_at,
                routerPhoto: waba?.routerPhoto,
                routerName: waba?.routerName,
                routerId: waba?.routerId,
                router: waba?.router,
                defaultFlowId: waba?.defaultFlowId,
                defaultMasterBot: waba?.defaultMasterBot,
                defaultStateId: waba?.defaultStateId,
                routerOwner: waba?.routerOwner,
                key: waba?.key,
                username: waba?.username,
            }));

            setWabas(formattedWabas);
        } catch (error) {
            console.error('Erro ao buscar WaBas:', error);
            showToast('Erro ao carregar WaBas.', 'error');
        } finally {
            setLoadingWabas(false);
        }
    }, [username]);


    const fetchTemplates = useCallback(async () => {
        setLoadingTemplates(true);
        try {
            const response = await axios.get('https://n8n.dawntech.dev/webhook/templates', {
                headers: {
                    'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                },
            });
            setTemplates(response.data.templates || []);
        } catch (error) {
            console.error('Erro ao buscar templates:', error);
            showToast('Erro ao carregar templates.', 'error');
        } finally {
            setLoadingTemplates(false);
        }
    }, [username]);



    const handleAddWaba = async () => {
        if (!newWabaId) {
            showToast('O ID da WaBa é obrigatório.', 'warning');
            return;
        }

        try {
            await axios.post(
                'https://n8n.dawntech.dev/webhook/waba',
                { wabaId: newWabaId },
                {
                    headers: {
                        'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                    },
                }
            );
            showToast('WaBa adicionada com sucesso!', 'success');
            setOpenModal(false);
            setNewWabaId('');
            fetchWabas();
        } catch (error) {
            console.error('Erro ao adicionar WaBa:', error);
            showToast('Erro ao adicionar WaBa.', 'error');
        }
    };


    const fetchCampaigns = useCallback(async () => {
        setLoadingCampaigns(true);
        try {
            const response = await axios.get(
                `https://n8n.dawntech.dev/webhook/campaigns?username=${username}`,
                {
                    headers: {
                        'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                    },
                }
            );

            const fetchedCampaigns = response.data.campaigns.map((campaign: any) => ({
                campaignId: campaign.campaignId,
                masterBot: campaign.masterBot,
                flowId: campaign.flowId,
                stateId: campaign.stateId,
                template: campaign.template,
                chatbot: campaign.router,
                key: campaign.key,
                data: new Date(campaign.date).toLocaleString(),
                totalContacts: campaign.totalContacts,
                totalDelivered: campaign.totalDelivered,
                totalRead: campaign.totalRead,
                totalClicked: campaign.totalClicked,
                totalFailed: campaign.totalFailed,
                interval: campaign?.interval,
                files: campaign.files,
                templateQuantityParameters: campaign?.templateQuantityParameters
            }));

            setCampaigns(fetchedCampaigns);
        } catch (error) {
            console.error('Erro ao buscar campanhas:', error);
            showToast('Erro ao carregar campanhas.', 'error');
        } finally {
            setLoadingCampaigns(false);
        }
    }, [username]);

    useEffect(() => {
        if (username) {
            fetchCampaigns();
        }
    }, [username, fetchCampaigns]);

    useEffect(() => {
        if (username) {
            fetchTemplates();
        }
    }, [username, fetchTemplates]);

    useEffect(() => {
        if (username) {
            fetchWabas();
        }
    }, [username, fetchWabas]);

    const [toast, setToast] = useState<{
        open: boolean;
        message: string;
        severity: 'success' | 'error' | 'warning' | 'info';
    }>({
        open: false,
        message: '',
        severity: 'success',
    });

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const showToast = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
        setToast({ open: true, message, severity });
    };

    useEffect(() => {
        const session = localStorage.getItem('session');
        const savedUsername = localStorage.getItem('username');
        if (session && savedUsername) {
            setUsername(savedUsername);
            setLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        if (username) {
            fetchCampaigns();
        }
    }, [username, fetchCampaigns]);


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const routerParam = queryParams.get('router');
        const keyParam = queryParams.get('key');
        const templateParam = queryParams.get('template');
        const batchCountParam = queryParams.get('batchCount');
        const intervalParam = queryParams.get('interval');
        const flowIdParam = queryParams.get('flowId');
        const masterBotParam = queryParams.get('masterBot');
        const stateIdParam = queryParams.get('stateId');

        if (routerParam) setRouter(routerParam);
        if (keyParam) setKey(keyParam.replace(/%20/g, ' '));
        if (templateParam) setTemplate(templateParam);
        if (batchCountParam) setBatchCount(batchCountParam);
        if (intervalParam) setInterval(intervalParam);
        if (flowIdParam) setFlowId(flowIdParam);
        if (masterBotParam) setMasterBot(masterBotParam);
        if (stateIdParam) setStateId(stateIdParam);
    }, [location.search]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setCsvFile(file);
            setCsvFileName(file.name);
            setIsLoading(true);

            Papa.parse(file, {
                header: false,
                complete: (result) => {
                    const phoneNumbers = result.data.map((row) => {
                        if (Array.isArray(row) && row[0]) {
                            return String(row[0]);
                        }
                        return null;
                    }).filter(Boolean);

                    const uniqueNumbers = new Set(phoneNumbers).size - 1;
                    setUniqueContacts(uniqueNumbers);
                    setIsLoading(false);
                },
                error: () => {
                    setIsLoading(false);
                    showToast(`Erro ao processar o arquivo CSV.`, 'error');
                },
            });
        }
    };

    const handleBatchCountChange = (value: string) => {
        const numericValue = parseInt(value, 10);
        if (numericValue < 1) {
            setBatchCount('1');
        } else if (numericValue > 15000) {
            setBatchCount('15000');
        } else {
            setBatchCount(value);
        }
    };

    const handleStartDispatch = async () => {


        const batchCountValue = parseInt(batchCount, 10);
        const form = new FormData();
        form.append('idOfCampaign', idOfCampaign);
        form.append('csvFile', csvFile);
        form.append('router', router);
        form.append('key', key);
        form.append('template', template);
        form.append('templateQuantityParameters', templateQuantityParameters);
        form.append('batchCount', batchCountValue.toString());
        form.append('interval', interval);
        form.append('flowId', flowId);
        form.append('masterBot', masterBot);
        form.append('stateId', stateId);
        form.append('username', "helpdesk");

        try {
            await axios.post(
                'https://n8n.dawntech.dev/webhook/dispatch',
                form,
                {
                    headers: {
                        'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                    },
                    maxContentLength: Infinity,
                    maxBodyLength: Infinity,
                }
            );

            showToast(`Broadcast iniciado com ID: ${idOfCampaign}`, 'success');
        } catch (error) {
            console.error('Erro:', error);
            showToast('Erro ao iniciar o broadcast.', 'error');
        }
    };


    const handleMonitorCampaign = async () => {
        if (!template) {
            showToast('Todos os campos são obrigatórios!', 'warning');
            return;
        }

        const form = new FormData();
        form.append('id', template);
        form.append('template', template);
        form.append('csvFile', csvFile);
        form.append('router', router);
        form.append('key', key);
        form.append('username', username);

        try {
            await axios.post(
                'https://n8n.dawntech.dev/webhook/monitor',
                form,
                {
                    headers: {
                        'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                    },
                    maxContentLength: Infinity,
                    maxBodyLength: Infinity,
                }
            );

            showToast(`Monitoramento do id ${template} iniciado.`, 'success');
        } catch (error) {
            console.error('Erro:', error);
            showToast('Erro ao monitorar campanha.', 'error');
        }
    };


    if (!loggedIn) {
        return <Login onLoginSuccess={() => setLoggedIn(true)} />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#b9d2d8',
            }}
        >
            <DashboardHeader
                onLogout={() => {
                    localStorage.removeItem('session');
                    localStorage.removeItem('username');
                    setLoggedIn(false);
                }}
            />

            <Box sx={{ flex: 1 }}>
                <Box sx={{ height: 64 }} />

                <ResponsiveTabs tabIndex={tabIndex} handleTabChange={handleTabChange} />

                <Box sx={{ height: 48 }} />

                {tabIndex === 0 && (
                    <Box
                        sx={{
                            padding: '20px',
                            backgroundColor: '#b9d2d8',
                            minHeight: 'calc(100vh - 200px)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '20px',
                        }}
                    >
                        <Box sx={{ width: '100%', maxWidth: '600px', padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Gerar identificador de campanha
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Gera identificador único para a campanha em massa.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{ marginBottom: '10px', backgroundColor: '#32484e', color: '#fff' }}
                                onClick={() => {
                                    const id = `${username}-${Date.now()}`;
                                    setCampaignId(id);
                                    setIdOfCampaign(id);
                                    setCampaignIdInModal(id);
                                    setToast({
                                        open: true,
                                        message: 'Identificador gerado com sucesso!',
                                        severity: 'success',
                                    });
                                }}
                            >
                                GERAR ID
                            </Button>
                            {campaignId && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {campaignId}
                                    </Typography>
                                    <CopyToClipboard
                                        text={campaignId}
                                        onCopy={() =>
                                            setToast({
                                                open: true,
                                                message: 'Identificador copiado com sucesso!',
                                                severity: 'success',
                                            })
                                        }
                                    >
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                borderColor: '#32484e',
                                                color: '#000000',
                                                textTransform: 'none',
                                            }}
                                        >
                                            Copiar
                                        </Button>
                                    </CopyToClipboard>
                                </Box>
                            )}
                        </Box>

                        <Box sx={{ width: '100%', maxWidth: '600px', padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Gerar URL de CTA
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Gera URL para tracking em campanha em massa.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: '#32484e', color: '#fff' }}
                                onClick={handleOpenCtaModal}
                            >
                                Gerar URL
                            </Button>

                        </Box>

                        <Modal open={ctaModalOpen} onClose={() => setCtaModalOpen(false)}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    boxShadow: 24,
                                    p: 4,
                                    borderRadius: '8px',
                                }}
                            >
                                <Typography variant="h6" component="h2" sx={{ marginBottom: '20px' }}>
                                    Gerar URL de CTA
                                </Typography>

                                <Grid container spacing={3} justifyContent="center" maxWidth="600px">
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            fullWidth
                                            label="Selecione o Roteador"
                                            value={routerId}
                                            onChange={(e) => {
                                                const selectedWaba = wabas.find((waba) => waba.routerId === e.target.value);
                                                if (selectedWaba) {
                                                    setRouterId(selectedWaba.routerId);
                                                    setMasterBot(selectedWaba.defaultMasterBot);
                                                    setFlowId(selectedWaba.defaultFlowId);
                                                    setStateId(selectedWaba.defaultStateId);
                                                    setRouter(selectedWaba.router);
                                                    setKey(selectedWaba.key || '');
                                                }
                                            }}
                                            variant="outlined"
                                        >
                                            {wabas.map((waba) => (
                                                <MenuItem key={waba.routerId} value={waba.routerId}>
                                                    {waba.routerName} ({waba.number})
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="URL a Redirecionar"
                                            value={ctaUrl}
                                            onChange={(e) => setCtaUrl(e.target.value)}
                                        />

                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="ID da Campanha / Template"
                                            value={campaignIdInModal}
                                            onChange={(e) => setCampaignIdInModal(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            color: '#32484e',
                                            borderColor: '#32484e',
                                        }}
                                        onClick={() => setCtaModalOpen(false)}
                                    >
                                        Cancelar
                                    </Button>

                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#F05365',
                                            color: '#fff',
                                            textTransform: 'none',
                                        }}
                                        onClick={() => { handleGenerateUrl() }}
                                    >
                                        Gerar
                                    </Button>
                                </Box>
                                {generatedCtaUrl && (
                                    <Box sx={{ marginTop: '20px' }}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                            URL Gerada:
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            sx={{
                                                wordWrap: "break-word",
                                                marginBottom: "10px",
                                                fontStyle: "italic",
                                                cursor: "pointer",
                                                color: "black",
                                            }}
                                            onClick={() => showToast("URL copiada para a área de transferência!", "success")}
                                        >
                                            <CopyToClipboard text={generatedCtaUrl}>
                                                <span>{generatedCtaUrl}</span>
                                            </CopyToClipboard>
                                        </Typography>

                                        <CopyToClipboard
                                            text={generatedCtaUrl}
                                            onCopy={() =>
                                                setToast({
                                                    open: true,
                                                    message: 'URL copiada com sucesso!',
                                                    severity: 'success',
                                                })
                                            }
                                        >
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    borderColor: '#32484e',
                                                    color: '#32484e',
                                                    textTransform: 'none',
                                                }}
                                            >
                                                Copiar URL
                                            </Button>
                                        </CopyToClipboard>
                                    </Box>
                                )}
                            </Box>
                        </Modal>
                    </Box>
                )}


                {tabIndex === 1 && (
                    <Box
                        sx={{
                            padding: '20px',
                            backgroundColor: '#b9d2d8',
                            minHeight: 'calc(100vh - 200px)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Paper
                            sx={{
                                padding: '20px',
                                maxWidth: '1200px',
                                width: '100%',
                                backgroundColor: '#fff',
                                borderRadius: '4px',
                                boxShadow: 2,
                                display: 'flex',
                                gap: '20px',
                            }}
                        >
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="h5" gutterBottom sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
                                    Preencha os dados
                                </Typography>

                                <Grid container spacing={3} justifyContent="center" maxWidth="600px">
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            fullWidth
                                            label="Selecione o Roteador"
                                            value={routerId}
                                            onChange={(e) => {
                                                const selectedWaba = wabas.find((waba) => waba.routerId === e.target.value);
                                                if (selectedWaba) {
                                                    setRouterId(selectedWaba.routerId);
                                                    setMasterBot(selectedWaba.defaultMasterBot);
                                                    setFlowId(selectedWaba.defaultFlowId);
                                                    setStateId(selectedWaba.defaultStateId);
                                                    setRouter(selectedWaba.router);
                                                    setKey(selectedWaba.key || '');
                                                }
                                            }}
                                            variant="outlined"
                                        >
                                            {wabas.map((waba) => (
                                                <MenuItem key={waba.routerId} value={waba.routerId}>
                                                    {waba.routerName} ({waba.number})
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            fullWidth
                                            label="Selecione o Template"
                                            value={template}
                                            onChange={(e) => {
                                                const selectedTemplate = templates.find((template) => template.template === e.target.value);
                                                if (selectedTemplate) {
                                                    setTemplate(selectedTemplate.template);
                                                    setTemplateQuantityParameters(selectedTemplate.templateQuantityParameters);
                                                    setPreview(selectedTemplate.components);
                                                }
                                            }}
                                            variant="outlined"
                                        >
                                            {templates.map((template) => (
                                                <MenuItem key={template.template} value={template.template}>
                                                    {template.template}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="ID da Campanha"
                                            value={idOfCampaign}
                                            onChange={(e) => setIdOfCampaign(e.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Máximo de Contatos por Batch"
                                            value={batchCount}
                                            onChange={(e) => handleBatchCountChange(e.target.value)}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{ min: 1, max: 15000 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Intervalo entre Batches"
                                            value={interval}
                                            onChange={(e) => setInterval(e.target.value)}
                                            variant="outlined"
                                            select
                                        >
                                            <MenuItem value="2">2 Minutos</MenuItem>
                                            <MenuItem value="3">3 Minutos</MenuItem>
                                            <MenuItem value="5">5 Minutos</MenuItem>
                                            <MenuItem value="10">10 Minutos</MenuItem>
                                            <MenuItem value="15">15 Minutos</MenuItem>
                                            <MenuItem value="30">30 Minutos</MenuItem>
                                            <MenuItem value="45">45 Minutos</MenuItem>
                                            <MenuItem value="60">60 Minutos</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            component="label"
                                            sx={{ backgroundColor: '#32484e', color: '#fff' }}
                                        >
                                            Upload CSV
                                            <input type="file" accept=".csv" hidden onChange={handleFileChange} />
                                        </Button>
                                        {csvFileName && (
                                            <>
                                                <Typography variant="body2" sx={{ marginTop: '10px' }}>
                                                    Arquivo selecionado: {csvFileName}
                                                </Typography>
                                                <Typography variant="body2" sx={{ marginTop: '5px' }}>
                                                    Contatos únicos: {uniqueContacts}
                                                </Typography>
                                            </>
                                        )}
                                        {isLoading && (
                                            <Typography variant="body2" sx={{ marginTop: '10px', fontStyle: 'italic' }}>
                                                Processando arquivo...
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            sx={{ backgroundColor: '#f05365', color: '#fff' }}
                                            onClick={handleStartDispatch}
                                        >
                                            Criar Campanha
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    padding: '16px',
                                    backgroundColor: '#dbe7eb',
                                }}
                            >
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                    Pré-visualização
                                </Typography>
                                <Box
                                    sx={{
                                        padding: '20px',
                                        borderRadius: '8px',
                                        backgroundColor: '#fff',
                                        boxShadow: 3,
                                        textAlign: 'left',
                                    }}
                                >
                                    {preview.map((component: { type: string; text?: string; buttons?: { type: string; text: string; url?: string }[] }, index: number) => (
                                        <React.Fragment key={index}>
                                            {component.type === 'BODY' && (
                                                <div
                                                    style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: (component.text || '')
                                                            .replaceAll('\\n', '<br />') // Transformar \n em <br />
                                                            .replace(/\*(.*?)\*/g, '<strong>$1</strong>') // Negrito
                                                            .replace(/_(.*?)_/g, '<em>$1</em>'), // Itálico
                                                    }}
                                                />
                                            )}
                                            {component.type === 'BUTTONS' && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        marginTop: '10px',
                                                        gap: '10px',
                                                    }}
                                                >
                                                    {component.buttons?.map((button: { type: string; text: string; url?: string }, idx: number) => (
                                                        <Button
                                                            key={idx}
                                                            variant="contained"
                                                            sx={{
                                                                textTransform: 'none',
                                                                backgroundColor: '#f05365',
                                                                color: '#fff',
                                                            }}
                                                            component="a"
                                                            href={button.url || '#'}
                                                            target={button.url ? '_blank' : undefined}
                                                            rel={button.url ? 'noopener noreferrer' : undefined}
                                                        >
                                                            {button.text}
                                                        </Button>
                                                    ))}
                                                </Box>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </Box>









                        </Paper>
                    </Box>
                )}


                {tabIndex === 2 && (
                    <Box
                        sx={{
                            padding: '20px',
                            backgroundColor: '#b9d2d8',
                            minHeight: 'calc(100vh - 200px)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Paper
                            sx={{
                                padding: '20px',
                                maxWidth: '600px',
                                width: '100%',
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                boxShadow: 3,
                            }}
                        >
                            <Typography variant="h5" gutterBottom sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
                                Monitorar Campanha
                            </Typography>

                            <Grid container spacing={3} justifyContent="center" maxWidth="600px">
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        fullWidth
                                        label="Selecione o Roteador"
                                        value={routerId}
                                        onChange={(e) => {
                                            const selectedWaba = wabas.find((waba) => waba.routerId === e.target.value);
                                            console.log(selectedWaba)
                                            if (selectedWaba) {
                                                setRouterId(selectedWaba?.routerId);
                                                setRouter(selectedWaba.router)
                                                setKey(selectedWaba?.key || '');
                                            }
                                        }}
                                        variant="outlined"
                                    >
                                        {wabas.map((waba) => (
                                            <MenuItem key={waba.routerId} value={waba.routerId}>
                                                {waba.routerName} ({waba.number})
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nome da campanha (início)"
                                        value={template}
                                        onChange={(e) => setTemplate(e.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{ backgroundColor: '#f05365', color: '#fff' }}
                                        onClick={handleMonitorCampaign}
                                    >
                                        Monitorar Campanha
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                )}



                {tabIndex === 3 && (
                    <Box
                        sx={{
                            padding: '20px',
                            backgroundColor: '#b9d2d8',
                            minHeight: 'calc(100vh - 200px)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '20px',
                            }}
                        >
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                Histórico de Disparos
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={fetchCampaigns}
                                sx={{ backgroundColor: '#32484e', color: '#fff' }}
                            >
                                Atualizar
                            </Button>
                        </Box>
                        {loadingCampaigns ? (
                            <Typography variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic' }}>
                                Carregando campanhas...
                            </Typography>
                        ) : (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>ID</TableCell>
                                            <TableCell>Template</TableCell>
                                            <TableCell>Contrato Blip</TableCell>
                                            <TableCell>Data de Adição</TableCell>
                                            <TableCell>Total de Disparos</TableCell>
                                            <TableCell>Mensagens Entregues</TableCell>
                                            <TableCell>Mensagens Lidas</TableCell>
                                            <TableCell>Cliques</TableCell>
                                            <TableCell>Falhas</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {campaigns.map((disparo, index) => (
                                            <Row key={index} disparo={disparo} index={index} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>
                )}

                {tabIndex === 4 && (
                    <Box
                        sx={{
                            padding: '20px',
                            backgroundColor: '#b9d2d8',
                            minHeight: '100%',
                            paddingBottom: '80px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '20px',
                            }}
                        >
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                Contas
                            </Typography>
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                <Button
                                    variant="contained"
                                    onClick={fetchWabas}
                                    sx={{ backgroundColor: '#32484e', color: '#fff' }}
                                >
                                    Atualizar
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleOpenModal}
                                    sx={{ backgroundColor: '#F05365', color: '#fff' }}
                                >
                                    Adicionar
                                </Button>
                            </Box>
                        </Box>
                        {loadingWabas ? (
                            <Typography variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px' }}>
                                Atualizando contas monitoradas...
                            </Typography>
                        ) : (
                            <Grid container spacing={6}>
                                {wabas.map((waba) => (
                                    <Grid item xs={12} sm={6} md={6} key={waba.id}>
                                        <Paper
                                            sx={{
                                                padding: '16px',
                                                border: '1px solid black',
                                                borderRadius: '4px',
                                                backgroundColor: '#fff',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                height: '100%',
                                                position: 'relative',
                                            }}
                                        >
                                            {waba.routerPhoto && (
                                                <Box
                                                    component="img"
                                                    src={waba.routerPhoto}
                                                    alt="Router"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '10px',
                                                        right: '10px',
                                                        width: '64px',
                                                        height: '64px',
                                                        borderRadius: '4px',
                                                        border: '2px solid #32484e',
                                                    }}
                                                />
                                            )}
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '8px',
                                                    color: '#32484e',
                                                }}
                                            >
                                                {waba.routerName} ({waba.name})
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    marginBottom: '8px',
                                                    color: '#32484e',
                                                }}
                                            >
                                                <b>WhatsApp:</b> {waba.number}
                                            </Typography>

                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    marginBottom: '8px',
                                                    color: '#32484e',
                                                }}
                                            >
                                                <b>ID do Roteador:</b> {waba.routerId}
                                            </Typography>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: '8px',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '12px',
                                                        height: '12px',
                                                        borderRadius: '50%',
                                                        marginRight: '8px',
                                                        backgroundColor:
                                                            waba.status === 'GREEN'
                                                                ? 'green'
                                                                : waba.status === 'YELLOW'
                                                                    ? 'yellow'
                                                                    : 'red',
                                                    }}
                                                />
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                    {waba.status === 'GREEN'
                                                        ? 'Saúde Alta'
                                                        : waba.status === 'YELLOW'
                                                            ? 'Saúde Média'
                                                            : 'Saúde Baixa'}
                                                </Typography>
                                            </Box>
                                            <Typography variant="body2" color="textSecondary">
                                                <b>Última atualização:</b>{' '}
                                                {waba.lastUpdatedAt
                                                    ? (() => {
                                                        const date = new Date(waba.lastUpdatedAt);
                                                        date.setHours(date.getHours());
                                                        return date.toLocaleString('pt-BR');
                                                    })()
                                                    : 'Data inválida'}
                                            </Typography>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    gap: '10px',
                                                    marginTop: 'auto',
                                                }}
                                            >

                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#fff', color: '#000', borderColor: '#32484e' }}
                                                    onClick={() => {
                                                        setOpenModal(true);
                                                        setRouter(waba.routerId);
                                                    }}
                                                >
                                                    Preencher dados
                                                </Button>
                                                {/*
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        color: '#fff',
                                                        backgroundColor: '#F05365'
                                                    }}
                                                    onClick={async () => {
                                                        try {
                                                            await axios.post(
                                                                'https://n8n.dawntech.dev/webhook/setdefault',
                                                                {
                                                                    id: waba.routerId,
                                                                    username,
                                                                },
                                                                {
                                                                    headers: {
                                                                        'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                                                                    },
                                                                }
                                                            );
                                                            showToast('Roteador e informações definidos como padrão para próximas campanhas', 'success');
                                                        } catch {
                                                            showToast('Erro ao definir padrão', 'error');
                                                        }
                                                    }}
                                                >
                                                    Definir padrão
                                                </Button>
                                                */}
                                            </Box>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                        <Modal open={openModal} onClose={handleCloseModal}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    boxShadow: 24,
                                    p: 4,
                                    borderRadius: '8px',
                                }}
                            >
                                <Typography variant="h6" component="h2" sx={{ marginBottom: '20px' }}>
                                    Preencher dados padrão
                                </Typography>
                                <TextField
                                    fullWidth
                                    label="ID do Builder de Retorno"
                                    value={masterBot}
                                    onChange={(e) => setMasterBot(e.target.value)}
                                    sx={{ marginBottom: '20px' }}
                                />
                                <TextField
                                    fullWidth
                                    label="Flow ID"
                                    value={flowId}
                                    onChange={(e) => setFlowId(e.target.value)}
                                    sx={{ marginBottom: '20px' }}
                                />
                                <TextField
                                    fullWidth
                                    label="State ID"
                                    value={stateId}
                                    onChange={(e) => setStateId(e.target.value)}
                                    sx={{ marginBottom: '20px' }}
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button
                                        variant="outlined"
                                        sx={{ color: '#32484e', borderColor: '#32484e' }}
                                        onClick={handleCloseModal}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: '#F05365', color: '#fff' }}
                                        onClick={async () => {
                                            try {
                                                await axios.post(
                                                    'https://n8n.dawntech.dev/webhook/setrouterinfo',
                                                    {
                                                        router: router,
                                                        username: username,
                                                        template,
                                                        masterBot,
                                                        flowId,
                                                        stateId,
                                                    },
                                                    {
                                                        headers: {
                                                            'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                                                        },
                                                    }
                                                );
                                                showToast('Dados salvos com sucesso', 'success');
                                                handleCloseModal();
                                            } catch {
                                                showToast('Erro ao salvar os dados', 'error');
                                            }
                                        }}
                                    >
                                        Salvar
                                    </Button>
                                </Box>
                            </Box>
                        </Modal>
                    </Box>
                )}

                {tabIndex === 5 && (
                    <Box
                        sx={{
                            padding: '20px',
                            backgroundColor: '#b9d2d8',
                            minHeight: '100%',
                            paddingBottom: '80px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '20px',
                            }}
                        >
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                Templates
                            </Typography>
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                <Button
                                    variant="contained"
                                    onClick={fetchTemplates}
                                    sx={{ backgroundColor: '#32484e', color: '#fff' }}
                                >
                                    Atualizar
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => setOpenTemplateModal(true)}
                                    sx={{ backgroundColor: '#F05365', color: '#fff' }}
                                >
                                    Adicionar
                                </Button>
                            </Box>
                        </Box>

                        {loadingTemplates ? (
                            <Typography variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic' }}>
                                Carregando templates...
                            </Typography>
                        ) : (
                            <Grid container spacing={6}>
                                {templates.map((template) => (
                                    <Grid item xs={12} sm={6} md={6} key={template._id}>
                                        <Paper
                                            sx={{
                                                padding: '16px',
                                                border: '1px solid black',
                                                borderRadius: '4px',
                                                backgroundColor: '#fff',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                height: '100%',
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '8px',
                                                    color: '#32484e',
                                                }}
                                            >
                                                {template.template}
                                            </Typography>
                                            <Typography variant="body2" sx={{ marginBottom: '8px' }}>
                                                <b>ID Contrato Blip:</b> {template.router}
                                            </Typography>
                                            <Typography variant="body2" sx={{ marginBottom: '8px' }}>
                                                <b>WhatsApp:</b> {template.name} ({template.number})
                                            </Typography>
                                            <Typography variant="body2" sx={{ marginBottom: '8px' }}>
                                                <b>Categoria:</b> <i>{template.category}</i>
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: '8px',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '12px',
                                                        height: '12px',
                                                        borderRadius: '50%',
                                                        marginRight: '8px',
                                                        backgroundColor:
                                                            template.status === 'APPROVED'
                                                                ? 'green'
                                                                : template.status === 'PENDING'
                                                                    ? 'yellow'
                                                                    : 'red',
                                                    }}
                                                />
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                    {template.status === 'APPROVED'
                                                        ? 'Aprovado'
                                                        : template.status === 'PENDING'
                                                            ? 'Pendente'
                                                            : template.status === 'DISABLED' ? 'Desabilitado ('.concat(template.reason, ')') : 'Rejeitado ('.concat(template.reason, ')')}
                                                </Typography>
                                            </Box>
                                            <Typography variant="body2" color="textSecondary">
                                                <b>Última atualização:</b>{' '}
                                                {template.updated_at
                                                    ? new Date(template.updated_at).toLocaleString('pt-BR', {
                                                        timeZone: 'America/Sao_Paulo',
                                                    })
                                                    : 'Data inválida'}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        )}

                        <Modal open={openTemplateModal} onClose={() => setOpenTemplateModal(false)}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    boxShadow: 24,
                                    p: 4,
                                    borderRadius: '8px',
                                }}
                            >
                                <Typography variant="h6" component="h2" sx={{ marginBottom: '20px' }}>
                                    Adicionar Template
                                </Typography>
                                <Grid container spacing={3} justifyContent="center" maxWidth="600px">
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            fullWidth
                                            label="Selecione o Roteador"
                                            value={routerId}
                                            onChange={(e) => {
                                                const selectedWaba = wabas.find((waba) => waba.routerId === e.target.value);
                                                console.log(selectedWaba)
                                                if (selectedWaba) {
                                                    setRouterId(selectedWaba?.routerId);
                                                    setMasterBot(selectedWaba?.defaultMasterBot);
                                                    setFlowId(selectedWaba?.defaultFlowId);
                                                    setStateId(selectedWaba?.defaultStateId);
                                                    setRouter(selectedWaba.router)
                                                    setKey(selectedWaba?.key || '');
                                                }
                                            }}
                                            variant="outlined"
                                        >
                                            {wabas.map((waba) => (
                                                <MenuItem key={waba.routerId} value={waba.routerId}>
                                                    {waba.routerName} ({waba.number})
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Template"
                                            value={templateName}
                                            onChange={(e) => setTemplateName(e.target.value)}
                                            sx={{ marginBottom: '20px' }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} justifyContent="center" maxWidth="600px">
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Quantidade de parâmetros"
                                            value={templateQuantityParameters}
                                            onChange={(e) => setTemplateQuantityParameters(e.target.value)}
                                            sx={{ marginBottom: '20px' }}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button
                                        variant="outlined"
                                        sx={{ color: '#32484e', borderColor: '#32484e' }}
                                        onClick={() => setOpenTemplateModal(false)}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: '#F05365', color: '#fff' }}
                                        onClick={async () => {
                                            if (!router || !key || !templateName) {
                                                showToast('Todos os campos são obrigatórios!', 'warning');
                                                return;
                                            }

                                            try {
                                                await axios.post(
                                                    'https://n8n.dawntech.dev/webhook/template',
                                                    {
                                                        router,
                                                        key,
                                                        templateName,
                                                        username,
                                                        templateQuantityParameters
                                                    },
                                                    {
                                                        headers: {
                                                            'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                                                        },
                                                    }
                                                );

                                                setTimeout(() => {
                                                    fetchTemplates();
                                                }, 2500);
                                                setTimeout(() => {
                                                    showToast('Template adicionado com sucesso!', 'success');
                                                    setOpenTemplateModal(false);
                                                    setTemplateName('');
                                                }, 3000);
                                            } catch (error) {
                                                console.error('Erro ao adicionar Template:', error);
                                                showToast('Erro ao adicionar Template.', 'error');
                                            }
                                        }}
                                    >
                                        Adicionar
                                    </Button>

                                </Box>
                            </Box>
                        </Modal>
                    </Box>
                )}

                {tabIndex === 6 && (
                    <Box
                        sx={{
                            padding: '20px',
                            backgroundColor: '#b9d2d8',
                            minHeight: 'calc(100vh - 200px)',
                            paddingBottom: '80px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Account />
                        </Box>
                    </Box>
                )}
                <Snackbar
                    open={toast.open}
                    autoHideDuration={3000}
                    onClose={() => setToast({ ...toast, open: false })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={() => setToast({ ...toast, open: false })}
                        severity={toast.severity}
                        sx={{ width: '100%' }}
                    >
                        {toast.message}
                    </Alert>
                </Snackbar>
                <Footer>
                    <Typography>Copyright © 2024 Dawntech Inc.</Typography>
                    <Typography>DAWNTECH CONSULTORIA LTDA</Typography>
                    <Typography>CNPJ: 38.179.807/0001-06</Typography>
                    <Typography>
                        Avenida Carlos Gomes, 700 sala 606, 5º Andar - Boa Vista, Porto Alegre, RS, Brasil,
                        90480-000
                    </Typography>
                    <Typography>
                        Telefones:
                        <a href="tel:+555135572998" style={{ color: 'white', textDecoration: 'none' }}>
                            +55 (51) 3557-2998
                        </a>{' '}
                        /
                        <a href="tel:+441245790914" style={{ color: 'white', textDecoration: 'none' }}>
                            +44 (1) 245 790 914
                        </a>{' '}
                        /
                        <a href="tel:+525584214909" style={{ color: 'white', textDecoration: 'none' }}>
                            +52 55 8421 4909
                        </a>
                    </Typography>
                </Footer>
            </Box>
        </Box>
    );
};


export default Dashboard;
