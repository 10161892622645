import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Typography,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Snackbar,
    Alert,
    Grid,
    Paper,
} from '@mui/material';
import axios from 'axios';

const Account: React.FC = () => {
    const [account, setAccount] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [toast, setToast] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
        open: false,
        message: '',
        severity: 'success',
    });

    const username = localStorage.getItem('username');
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        const fetchAccount = async () => {
            try {
                const response = await axios.get(`https://n8n.dawntech.dev/webhook/account?username=${username}`, {
                    headers: {
                        'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                    },
                });
                setAccount(response.data);
            } catch (error) {
                console.error('Erro ao carregar informações da conta:', error);
                setToast({ open: true, message: 'Erro ao carregar informações da conta.', severity: 'error' });
            } finally {
                setLoading(false);
            }
        };

        fetchAccount();
    }, [username]);

    const handleInputChange = (field: string, value: any) => {
        setAccount((prev: any) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleNotificationsChange = (field: string, value: boolean) => {
        setAccount((prev: any) => ({
            ...prev,
            notifications_config: {
                ...prev.notifications_config,
                [field]: value,
            },
        }));
    };

    const handleSave = async () => {
        try {
            await axios.put(
                `https://n8n.dawntech.dev/webhook/account?username=${username}`,
                account,
                {
                    headers: {
                        'api-key': '73b53e37-79df-478f-a16b-2ee0928d6ab8',
                    },
                }
            );
            setToast({ open: true, message: 'Dados salvos com sucesso!', severity: 'success' });
        } catch (error) {
            console.error('Erro ao salvar as informações da conta:', error);
            setToast({ open: true, message: 'Erro ao salvar informações da conta.', severity: 'error' });
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h6">Carregando informações...</Typography>
            </Box>
        );
    }

    return (
        <Box>

            <Box sx={{ height: 64 }} />

            <Box
                sx={{
                    padding: '20px',
                    backgroundColor: '#b9d2d8',
                    minHeight: '100%',
                    paddingBottom: '20px',
                }}
            >
                <Paper
                    sx={{
                        padding: '20px',
                        maxWidth: '600px',
                        width: '100%',
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        boxShadow: 3,
                    }}
                >
                    <Typography variant="h5" sx={{ marginBottom: '20px', fontWeight: 'bold', textAlign: 'center' }}>
                        Minha Conta
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                label="Nome"
                                value={account.name}
                                fullWidth
                                onChange={(e) => handleInputChange('name', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                value={account.email}
                                fullWidth
                                onChange={(e) => handleInputChange('email', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Telefone"
                                value={account.phone}
                                fullWidth
                                onChange={(e) => handleInputChange('phone', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                Configurações de Notificações
                            </Typography>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={account.notifications_config.in_app}
                                            onChange={(e) => handleNotificationsChange('in_app', e.target.checked)}
                                        />
                                    }
                                    label="Notificações In-App"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={account.notifications_config.phone}
                                            onChange={(e) => handleNotificationsChange('phone', e.target.checked)}
                                        />
                                    }
                                    label="Notificações por Telefone"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={account.notifications_config.email}
                                            onChange={(e) => handleNotificationsChange('email', e.target.checked)}
                                        />
                                    }
                                    label="Notificações por Email"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ backgroundColor: '#32484e', color: '#fff' }}
                                onClick={handleSave}
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Snackbar
                    open={toast.open}
                    autoHideDuration={3000}
                    onClose={() => setToast({ ...toast, open: false })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={() => setToast({ ...toast, open: false })}
                        severity={toast.severity}
                        sx={{ width: '100%' }}
                    >
                        {toast.message}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default Account;
