import { FunnelChart, Funnel, LabelList } from 'recharts';
import { Disparo } from './Dashboard';

const FunnelData = ({ disparo }: { disparo: Disparo }) => {
  const total = disparo.totalContacts;

  const colors = ['#d4edda', '#a8d5b9', '#6fcf97', '#3da86d'];

  const data = [
    { name: 'Contatos', value: disparo.totalContacts, percentage: 100, fill: colors[0] },
    {
      name: 'Mensagens Entregues',
      value: disparo.totalDelivered,
      percentage: ((disparo?.totalDelivered / total) * 100).toFixed(1),
      fill: colors[1],
    },
    {
      name: 'Mensagens Lidas',
      value: disparo.totalRead,
      percentage: ((disparo.totalRead / total) * 100).toFixed(1),
      fill: colors[2],
    },
    ...(disparo.totalClicked !== null && disparo.totalClicked > 0
      ? [
        {
          name: 'Cliques',
          value: disparo.totalClicked,
          percentage: ((disparo.totalClicked / total) * 100).toFixed(1),
          fill: colors[3],
        },
      ]
      : []),
  ];

  return (
    <FunnelChart
      width={600}
      height={400}
      style={{ margin: '0 auto', overflow: 'visible' }}
      id="chart-container"
    >
      <Funnel dataKey="value" data={data} isAnimationActive>
        <LabelList
          dataKey="name"
          position="right"
          style={{
            fill: 'black',
            fontSize: '14px'
          }}
        />
        <LabelList
          dataKey="percentage"
          position="center"
          content={(props) => {
            const { x, y, value } = props;

            const adjustedX = typeof x === 'number' ? x - 50 : 30;
            const adjustedY = typeof y === 'number' ? y + 53 : 300;

            return (
              <text
                x={adjustedX}
                y={adjustedY}
                textAnchor="center"
                style={{
                  fill: 'black',
                  fontSize: '14px',
                }}
              >
                {`${value}%`}
              </text>
            );
          }}
        />
      </Funnel>
    </FunnelChart>
  );
};

export default FunnelData;
